//@import "compass";
@import "colors.scss";
@import "images.scss";

$borderRadiusDefault:3px;

/* Vars */
$font-family:"Lucida Grande","Lucida Sans Unicode","Lucida Sans","Tahoma";

/* Mixins */
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin border-radius-special($radius) {
  -webkit-border-bottom-right-radius: $radius;
     -moz-border-bottom-right-radius: $radius;
      -ms-border-bottom-right-radius: $radius;
          border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
     -moz-border-bottom-left-radius: $radius;
      -ms-border-bottom-left-radius: $radius;
          border-bottom-left-radius: $radius;
}
@mixin noSelect{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin button-icon($img, $left, $top) {
  background:url($img);
  background-position: $left $top;
  background-repeat: no-repeat;
  background-color:$colorCorporateLightTurquoise;
  color: $colorCorporateTurquoise;
}
@mixin button-icon-hover($img, $left, $top) {
  background:url($img);
  background-position: $left $top;
  background-repeat: no-repeat;
  background-color:$colorCorporateTurquoise;
  color: $colorCorporateLightTurquoise;
}
@mixin button-icon-active($img, $left, $top) {
   background:url($img);
   background-position: $left $top;
   background-repeat: no-repeat;
   background-color:$colorCorporateTurquoise;
   color: $colorCorporateLightTurquoise;
}

/* Defaults */
@mixin borderRadiusDefault{
  @include border-radius($borderRadiusDefault);
}
@mixin fontDefault{
  font-family:$font-family;
  font-size:11px;
}

#reportzeilen{
  transition:padding 0.2s;
  #actionbarHolder{
    position: fixed;
    top:203px;
    width:100%;
    z-index:1000;
    height:0px;
    overflow:hidden;
  }
  &.selectmode{
    padding-top:80px;
    #actionbarHolder{
      height:29px;
    }
    #actionbar{
      top:-1px;
    }
  }
}
#actionbar{
  @include border-radius-special($borderRadiusDefault);
  @include fontDefault;
  @include noSelect;
  background-color:$colorCorporateLightTurquoise;
  border:1px solid $colorMidGray;
  top:-30px;
  position: absolute;
  margin-right:20px;
  height:28px;
  width:1076px;
  transition:top 0.2s;
  label{
    color:$colorCorporateTurquoise;
    line-height:28px;
    margin:0px 10px;
    display:inline-block;
    padding:0px;
  }
  button{
    @include fontDefault;
    @include border-radius($borderRadiusDefault);
    background:transparent;
    border:solid 1px $colorCorporateTurquoise;
    color:$colorCorporateTurquoise;
    height:18px;
    margin-right:5px;
    padding:0 2px 0 16px;
    text-align:left;
    &.delete{
      @include button-icon($delete_00abd3, 4px, 2px);
      &:hover{
        @include button-icon-hover($delete_E2F1F7, 4px, 2px);
      }
      &:active{
        @include button-icon($delete_00abd3, 4px, 2px);
      }
    }
    &.close{
      @include border-radius(20px);
      @include button-icon($close_01aad3, center, center);
      border:none;
      padding:0px;
      position: absolute;
      right:0px;
      top:5px;
      height:18px;
      overflow: hidden;
      text-indent:-100px;
      width:18px;
      &:hover{
        @include button-icon-hover($close_E2F1F7, center, center);
      }
      &:active{
        @include button-icon($close_01aad3, center, center);
      }
    }
  }
}
