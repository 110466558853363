@charset "UTF-8";
@page {
    margin-top: 20mm;
    margin-bottom: 20mm;
    margin-right: 15mm;
    margin-left: 20mm;
  }
@media print {
    html, body {
        color: #000;
        font-size: 13px;
        font-family: "Lucida Grande", "Lucida Sans", "Lucida Sans Unicode", "Tahoma";
        line-height: 18px;
        text-align: center;
        background: #fff!important;
        height:auto;
    }
    body {
        
        height:auto;
    }
    #documentheader{
        position: static;
        div:last-child{
            height:auto;
        }

    }
    #hauptrahmen, #content-main {
        text-align: left;
        display: none;
    }
    .Moduleholder.default{
        margin: 0;
        padding: 0;
        top: 0px!important;
        position:static!important;
    }
    .infopage{
        margin: 0px;
        *{
        	padding: 0;
        	margin: 0;
        }
        #document_middle{
            background-color:#fff;
        }
        h1{
        	color: #10193A;
            font-family: Georgia;
            font-size: 27px;
            font-weight: normal;
            line-height: 35px;
            margin: 10px 0 30px;
            padding: 0;
        }
        p{
        	margin-bottom:16px;
        }
        a{
        	color: #4EA9D0;
        	text-decoration:none;
        }
        a:hover{
        	color: #4EA9D0;
        	text-decoration:underline;
        }
        hr{
        	border:none;
        	border-top:dotted 1px #10193A;
        	background:#fff;
        	margin:20px -10px 20px -10px;
        }
        .licenseul li{
        	margin-bottom:16px;
        	list-style-type: none;
        }
        .licenseul small{
        	display:block;
        }
        .buttonsfooter{
        	display:none;
        }
    }
    .reactSpinner {
        display: none;
    }
    .hide-in-print {
        display: none;
    }
    #routerLoader {
        display: none;
    }
    td {
        border-right: solid 1px #ccc;
        border-bottom: 1px solid #ccc;
        text-align: left;
    }
    th {
        border-right: solid 1px #ccc;
        border-bottom: 1px solid #ccc;
        text-align: left;
    }
    .table_wrapper {
        display: flex;
    }
}
        