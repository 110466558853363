/* Suchleiste */
#suchleiste {
    position: fixed;
    top: 143px;
    width: 1078px;
    height: 70px;
    background-position: left -60px;
    z-index: 999;
    &.noGradient{
        background-color: #fff;
    }
}
#suchleiste:hover {
    background-position: left -130px;
}
div#suchleiste div {
    margin-top: 30px;
    margin-left: 0px;
}
#suchleiste form {
    margin: 0px;
    height: 30px;
}
#suchleiste fieldset,
.searchbarElement fieldset {
    border: none;
    float: right;
    padding: 0px !important;
    margin: 0px !important;
}
#suchleiste fieldset label,
.searchbarElement fieldset label {
    color: #808080;
    font-size: 11px;
    display: inline-block;
    line-height: 8px;
    margin-left: 12px;
}
#suchleiste fieldset input,
.searchbarElement fieldset input {
    border: solid 1px #cccccc;
    font-size: 13px;
    background: #fff;
    padding: 0 5px 0 5px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    height: 22px;
    color: #333;
    margin: 3px 3px 0 5px;
    width: 145px;
    cursor: pointer;
}
#suchleiste fieldset input:active,
#suchleiste fieldset input:focus,
.searchbarElement fieldset input:active,
.searchbarElement fieldset input:focus {
    box-shadow: 0 0 2px #00abd3;
    background-color: #e2f1f7;
}
#suchleiste .datesearch {
    background-color: #cccccc;
    border: none;
    height: 18px;
    color: #808080;
    font-size: 11px;
    background-image: url(../images/sprites_horizontal_breite.png);
    background-position: right -364px;
    width: 80px;
    margin: 6px 3px 5px 5px;
    padding-right: 20px;
}
.searchbarElement .datesearch {
    background-color: #cccccc;
    border: none;
    height: 18px;
    color: #808080;
    font-size: 11px;
    background-image: url(../images/sprites_horizontal_breite.png);
    background-position: right -364px;
    width: 70px;
    margin: 0 3px 0 5px;
    padding-right: 20px;
}
#suchleiste:hover .datesearch,
.searchbarElement:hover .datesearch {
    color: #333;
    background-color: #aaa;
}
#suchleiste:hover label,
.searchbarElement label {
    color: #333;
}
#suchleiste span.buttonSearchbar,
.searchbarElement span.buttonSearchbar {
    float: left;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    background: #ccc;
    border: none;
    color: #808080;
    margin-left: 6px;
    margin-top: 6px;
    font-size: 11px;
    height: 22px;
    height: 18px;
    padding: 0 5px 0 5px;
    line-height: 18px;
    cursor: pointer;
}
#suchleiste:hover span.buttonSearchbar,
.searchbarElement:hover span.buttonSearchbar {
    color: #333;
    background-color: #aaa;
}
#suchleiste span.clearFilter,
.searchbarElement span.clearFilter {
    float: left;
}
#namesearch {
    cursor: text !important;
}
