@charset "UTF-8";
@media screen {
    .infopage {
        max-width: 1000px;
        padding: 50px 20px;
        margin: 0 auto;
        * {
            padding: 0;
            margin: 0;
        }
        h1 {
            color: #10193a;
            font-family: Georgia;
            font-size: 27px;
            font-weight: normal;
            line-height: 35px;
            margin: 10px 0 30px;
            padding: 0;
        }
        p {
            margin-bottom: 16px;
        }
        a {
            color: #4ea9d0;
            text-decoration: none;
        }
        a:hover {
            color: #4ea9d0;
            text-decoration: underline;
        }
        hr {
            border: none;
            border-top: dotted 1px #10193a;
            background: #fff;
            margin: 20px -10px 20px -10px;
        }
        .licenseul {
        }
        .licenseul li {
            margin-bottom: 16px;
            list-style-type: none;
        }
        .licenseul small {
            display: block;
        }

        .defaultButton {
            border: solid 1px #333;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            background: -moz-linear-gradient(top, #fff, #ccc); /* Firefox */
            background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ccc)); /* Safari, Chrome */
            background: -webkit-linear-gradient(top, #fff, #ccc); /* Safari, Chrome - neue Syntax! */
            background: -o-linear-gradient(top, #fff, #ccc); /* Opera */
            background: -ms-linear-gradient(top, #fff, #ccc); /* IE */
            background: linear-gradient(to bottom, #fff, #ccc); /* W3C Standard */
            -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#ffffff, endColorstr=#cccccc)';
            box-shadow: 0 0 2px #aaa;
        }
        .defaultButton:hover {
            box-shadow: none;
        }
        .defaultButton:active {
            background: -moz-linear-gradient(top, #ccc, #fff); /* Firefox */
            background: -webkit-gradient(linear, left top, left bottom, from(#ccc), to(#fff)); /* Safari, Chrome */
            background: -webkit-linear-gradient(top, #ccc, #fff); /* Safari, Chrome - neue Syntax! */
            background: -o-linear-gradient(top, #ccc, #fff); /* Opera */
            background: -ms-linear-gradient(top, #ccc, #fff); /* IE */
            background: linear-gradient(to bottom, #ccc, #fff); /* W3C Standard */
            -ms-filter: 'progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#ffffff, endColorstr=#cccccc)';
            box-shadow: none;
        }
        .buttonAutoFlat {
            padding: 0px 5px 0 5px;
            height: 22px;
            line-height: 0px;
            font-size: 11px;
        }
        .buttonsfooter {
            border-top: dotted 1px #10193a;
            padding: 20px 0;
            text-align: right;
            position: relative;
        }
    }
}
