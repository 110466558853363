/* Kopf */
#bg_kopf{
	height: 110px;
	background-image:url(../images/bg_kopf.png);
	background-repeat: no-repeat;
	width:100%;
	position:fixed;
	z-index:1000;
}
#kopf{
	width:2098px;
	height: 140px;
	font-size:11px;
	color:#fff;
	position:fixed;
	z-index:1001;
	background: #f00;
}
#logo{
	position:absolute;
	top:0px;
	left:0px;
	width:300px;
	height:110px;
	background:url(../images/logo.png);
	background-repeat: no-repeat;
	text-indent:-100000px;
}
#hilfebutton{
	width:30px;
	height:30px;
	display:block;
	text-indent:-1000px;
	overflow:hidden;
	background-position:-844px top;
	position:absolute;
	top: 10px;
	right:0px;
}
#hilfebutton:hover{
	background-position:-874px 0;
}
#hilfebutton.active{
	background-position:-904px top;
	color: #6cbbd8;
/*
	text-shadow: 0 0 2px #fff;
*/
}
#kundennummer_user{
	position:absolute;
	top:18px;
	right:44px;
}
#kundennummer_user div{
	display:inline-block;
}
#kundennummer_user #user{
	margin-left:20px;
	padding: 0 0 0 20px;
}
#kundennummer_user #user:hover{
	margin-left:20px;
	padding: 0 0 0 20px;
	background-position: left -16px;
}
#logout{
	width:30px;
	height:30px;
	display:block;
	text-indent:-1000px;
	overflow:hidden;
	background-position:left top;
	position:absolute;
	top: 51px;
	right:0px;
}
#logout:hover{
	background-position:-30px top;
}
#logout:focus{
	background-position:-60px top;
}
#modulwahl{
	position:absolute;
	right:252px;
	top:61px;
	width:300px;
	text-align:right;
}

#kopf .uiSelect{
	position:absolute;
	width:200px;
	top:54px;
	right:44px;
}
#kopf .uiSelect button{
	border:solid 1px #fff;font-size:13px;
	background:#00abd3;
	padding:0 5px 0 5px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	height:26px;
	float:right;
	color: #fff;
	text-align:left;
	background-image:url(../images/sprites_horizontal_breite.png);
	background-repeat: no-repeat;
	background-position: right -258px;
	width:200px;
	padding-bottom:2px;
	vertical-align:text-top;
}
:root #kopf .uiSelect button{
	padding-top:3px\0/IE9;;
}
#kopf .uiSelect button:active{
	background-color:#7d6f53;
	border:solid 1px #ac9c79;
	color:#fff;
}
#kopf .uiSelect button.active{
	box-shadow: 0 0 2px #00abd3;
	background-position: right -282px!important;
	background-color:#7d6f53;
	color:#fff;
}
#kopf .uiSelect ul{
	list-style-type:none;
	background:#7fd5e9;
	border:solid 1px #fff;
	position:absolute;
	display:none;
	padding: 10px 0 10px 10px;
	z-index:500;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}
#kopf .uiSelect ul li{
	margin-right:10px;
	line-height:22px!important;
	font-size:12px;
	padding-left: 5px;
	border: solid 1px #7fd5e9;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	color:#333;
	cursor:pointer;
}
#kopf .uiSelect ul li:hover, #kopf .uiSelect ul li.choosen{
	background: #eaf1f7;
	color:#333;
	border: solid 1px #7fd5e9;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}